
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonInput,
  IonTextarea,
  IonItem,
  IonLabel,
  IonPage,
  IonRange,
  IonRow,
  onIonViewDidLeave,
  toastController,
} from '@ionic/vue';
import { computed, defineComponent, inject, onMounted, ref, Ref } from 'vue';
import axios from 'axios';
import Lookup, { Country } from 'country-code-lookup';
import MessageBox from '@/components/MessageBox.vue';
import {useRoute, useRouter} from 'vue-router';
import { throttle } from 'lodash';
import {uploadMedia} from "@/common/utils";
import {FootholdType, getFootholdTypes} from "@/common/api/footholdType";

export default defineComponent({
  name: 'UploadRoute',
  components: {
    MessageBox,
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonInput,
    IonTextarea,
    IonItem,
    IonLabel,
    IonPage,
    IonRange,
    IonRow,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const content: Ref<typeof IonContent | null> = ref(null);
    const msgBox: Ref<typeof MessageBox | null> = ref(null);
    const getAccessToken: () => Ref<string> = inject('getAccessToken', () => ref(''));
    const getUsername: () => Ref<string> = inject('getUsername', () => ref(''));
    const routeNameText = ref('');
    const routeDescriptionText = ref('');
    const footholdType = ref(0);
    const gradeNumber = ref(0);
    const gradeText = computed(() => gradeNumber.value);
    const getRouteImageUri: () => Ref<string> = inject('getRouteImageUri', () => ref(''));
    const routeImage = getRouteImageUri();
    const countryNameList = ref<Array<Country>>([]);
    const gymId = computed(() => route.params.gym as string);
    const footholdTypeList = ref<Array<FootholdType>>(getFootholdTypes());

    const asciiPattern = /^[ -~]+$/;

    onIonViewDidLeave(() => {
      msgBox.value?.close();
    });

    onMounted(() => {
      countryNameList.value = [...Lookup.countries.sort()];
    });

    const isValidRouteName = (routeName: string): boolean => {
      return routeName.length > 0 && routeName.length <= 20 && asciiPattern.test(routeName);
    };

    const showErrorMsg = (errorMsg: string): void => {
      msgBox.value?.setColor('danger');
      msgBox.value?.showMsg(errorMsg);
      content.value?.$el.scrollToTop(400);
    };

    const onSubmit = throttle(async (): Promise<boolean> => {
      msgBox.value?.close();

      if (!isValidRouteName(routeNameText.value)) {
        showErrorMsg('Route name has to be 1-20 ASCII characters');
        return false;
      }

      const routeImageId = await uploadMedia(routeImage.value, showErrorMsg);
      if(routeImageId === null){
        return false;
      }
      
      try {
        const routeResponse = await axios.post(
            process.env.VUE_APP_ROUTE_ENDPOINT_URL + 'v2/routes', {
              routeName: routeNameText.value,
              description: routeDescriptionText.value,
              gym: gymId.value,
              ownerGrade: gradeNumber.value,
              footholdType: footholdType.value,
              routeImage: routeImageId
            }, {
              headers: {'Content-Type': 'application/json', Authorization: `Bearer ${getAccessToken().value}`},
            },
        );

        if (routeResponse.data.id) {
          const toast = await toastController.create({
            header: 'Route has been uploaded successfully',
            position: 'bottom',
            color: 'success',
            duration: 3000,
            buttons: [
              {
                text: 'Close',
                role: 'cancel',
              },
            ],
          });
          toast.present();

          router.push({ name: 'UserRoutes', params: { username: getUsername().value } });
        } else {
          showErrorMsg('Unable to create route, please try again');
        }
      } catch (error:any) {
        if (error.response) {
          if (error.response.data.Message === 'Unregistered gym') {
            showErrorMsg(
              'Unregistered gym, please go to https://routemaker.rocks/gyms/request to register',
            );
          } else if (error.response.data.Message === 'Upload Limit Reached') {
            showErrorMsg(
              `Daily upload limit of ${error.response.data.Limit} reached, please wait till tomorrow or delete some routes from today`,
            );
          } else {
            showErrorMsg('Error: ' + error.response.data.detail);
          }
        } else if (error.request) {
          showErrorMsg('Bad request');
        } else {
          showErrorMsg('Error: ' + error.message);
        }
      }
      return true;
    }, 1000);

    return {
      content,
      onSubmit,
      msgBox,
      routeImage,
      routeNameText,
      routeDescriptionText,
      gradeNumber,
      footholdType,
      footholdTypeList,
      gradeText,
    };
  },
});
